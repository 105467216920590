import "./FirstBlock.scss";
import { Header, Info } from "./components";
import Link from "../Common/Link/Link";
import Woman from "../../assets/bg-m1.png";
import Blur5 from "../../assets/Blur5.png";
import Blur6 from "../../assets/Blur6.png";

import HowWasIt from "./components/HowWasIt";
import { SwiperPlan } from "./components/Swiper.jsx";

const FirstBlock = ({ setTypeModal }) => {
  return (
    <div className="w-full h-full">
      <div className="main mx-auto ">
        <div className="max-w-[1400px] mx-auto pl-5 sm:pl-8 md:pl-14 py-5 pb-0 sm:py-8 md:py-14">
          <div className="absolute top-0 right-0 ">
            <img className={`z-10 woman_img`} src={Woman} alt="" />
          </div>
          <div className="relative z-10">
            <Header />
            <Info />
            <Link
              text="Хочу участвовать"
              color="red"
              setTypeModal={setTypeModal}
              type="form"
              className="relative z-10"
            />
            <div className="relative z-[-1]">
                <img
                  className={`absolute top-[-112px] left-[-350px] min-w-[780px] min-h-[818px] md:min-w-[1080px] md:min-h-[1118px] rotate-[-27deg]`}
                  src={Blur5}
                  alt=""
                />
            </div>
            <SwiperPlan />
            
            <div className="relative w-full hidden lg:block">
              <div
                className={`absolute top-[-325px] right-0 w-full min-w-[1200px]`}
              >
                <img
                  className={`relative w-[700px] sm:w-[900px] md:w-[1200px] lg:w-[1200px] xl:w-[1300px] translate-y-[8rem] sm:translate-y-[12rem] md:-translate-y-[26rem] xl:-translate-y-[22rem] translate-x-[53rem] sm:translate-x-[50rem] md:translate-x-[29rem] lg:translate-x-[26rem]`}
                  src={Blur6}
                  alt=""
                />
              </div>
            </div>
            <HowWasIt />

          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstBlock;
