import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "../FirstBlock.scss";

// import required modules
import {Autoplay, FreeMode, Pagination} from "swiper/modules";
import Plan from "./Plan";
import Advantages from "./Advantages";

export const SwiperPlan = () => {
  return (
    <>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={0}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
        }}
        speed={1000}
        modules={[FreeMode, Pagination, Autoplay]}
        className="mySwiper mt-10 md:mt-28 ">

        <SwiperSlide className="z-30 swiper-slide-1 h-[100%] w-[374px] sm:w-[634px] md:w-[734px]">
          <Plan />
          <Advantages />
        </SwiperSlide>
        <SwiperSlide className="xl:hidden w-[300px] md:w-[616px]">
        </SwiperSlide>
      </Swiper>
    </>
  );
};
