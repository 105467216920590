import React, {useEffect, useState} from "react";
import {ArrowToggle} from "../SecondBlock/icons";


const SelectOption = ({ value = "", active = false, updateValue, icon }) => {

    const handleChange = (e) => {
        e.preventDefault();

        updateValue(value);
    };

    return (
        <li
            className={`text-dark ${value !== 'Учусь в школе' && value !== 'Студент ссуза' ? 'border-t-[1px]': ''} cursor-pointer hover:bg-gray-100 hover:rounded-xl select-none relative py-5 pl-7 pr-9 text-base`}
            onClick={handleChange}
        >
            <div className="flex items-center">
                <span className="block font-normal truncate">{value}</span>
            </div>
            {active && (
                <span className="absolute inset-y-0 right-0 flex items-center pr-4">
          {icon}
        </span>
            )}
        </li>
    );
};

const SelectDetail = ({
    name = "customSelect",
    value,
    options = [],
    icon,
    setEducation,
    setEducationDetails,
    label,
    education
}) => {
    const [state, setState] = useState({
        value,
        showOptions: false
    });

    useEffect(() => {
        setState({ value: 'Ссуз / вуз *', showOptions: false })
    }, [education])

    const handleClick = (e) => {
        e.preventDefault();

        setState((p) => ({ ...p, showOptions: !state.showOptions }));
    };


    const updateValue = (value) => {
        if(label === 'Статус образования *') {
            setEducation(value);
            setEducationDetails(null);
        } else{
            setEducationDetails(value)
        }

        setState((p) => ({ ...p, showOptions: false, value }));
    };

    if (state.showOptions) {
        icon = (
            <ArrowToggle color="#fff" open={true} id={true}/>
        );
    }else{
        icon = (
            <ArrowToggle color="#fff" open={false} id={true}/>
        );
    }

    return (
        <div className={`relative cursor-pointer w-full`} onClick={handleClick}>
            <input type="hidden" value={state.value}
                   className="flex w-full bg-transparent border-[1px] border-white text-white rounded-2xl p-3.5 sm:px-6 sm:py-5 outline-none text-sm sm:text-xl md:text-2xl"
            />
            <div
                className="justify-between flex w-full bg-transparent border-[1px] border-white text-white rounded-2xl p-3.5 sm:px-6 sm:py-5 outline-none text-sm sm:text-xl md:text-2xl"
            >

                <span className="flex truncate">{state.value}</span>

                <span className="flex items-center pr-2">
                  {icon}
                </span>
            </div>
            {state.showOptions && (
                <div
                    className={`z-30 bg-white rounded-xl mt-3 lg:mt-5 absolute w-full`}>
                    <ul
                        className=""
                    >
                        {options.map((option, label) => (
                            <SelectOption
                                key={option}
                                value={option}
                                active={state.value === option}
                                updateValue={updateValue}
                            />
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};
export default SelectDetail;


