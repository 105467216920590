import InputMask from "react-input-mask-3.0"
import {forwardRef, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import Select from "./Select";
import { v4 as uuidv4 } from 'uuid';
import {format, subYears} from 'date-fns';
import DatePicker , { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import SelectDetail from "./SelectDetail";
import moment from "moment";
import Line from "../Common/Line/Line";
registerLocale("ru", ru);


const MaskedInput = forwardRef(({ value, onChange, onClick }, ref) => (
    <div className="!w-full flex ui input" onClick={onClick}>
        <InputMask ref={ref} mask="99.99.9999" value={value} onChange={onChange} placeholder="Дата рождения*"
             className="flex !w-full bg-transparent border-[1px] border-white rounded-2xl p-3.5 sm:px-6 sm:py-5 text-white outline-none text-sm sm:text-xl md:text-2xl"
        />
    </div>
));


const Form = ({placeId, setPlace, setTypeModal, setAddress}) => {
    const [grYear, setGrYear] = useState();
    const currentDate = new Date();
    const minDate = subYears(currentDate, 100);
    const maxDate = subYears(currentDate, 10);
    const [selectedDate, setSelectedDate] = useState();

    const [education, setEducation] = useState('');
    const [placeForLink, setPlaceForLink] = useState(placeId);
    const [educationDetails, setEducationDetails] = useState(null);
    const {
        register,
        handleSubmit,
        resetField,
        formState: { isDirty, isValid },
    } = useForm()

    useEffect(() => {
        resetField('specialization')
        resetField('institution')
        setEducationDetails(null);
        setGrYear(null);
    }, [education, resetField])

    const [isOpen, setIsOpen] = useState(false);
    const handleChange = (e) => {
        setIsOpen(!isOpen);
        setGrYear(e);
    };
    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    const url = 'https://future.cznmos.ru/app/yarmarka.php';

    const request = async (data) => {
        try {
            let response = await fetch(url, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS'
                },
                method: 'POST',
                body: JSON.stringify({...data})
            });

            if (response.ok) {
                return response.json();

            } else {
                alert("Ошибка HTTP: " + response.status);
            }
        } catch (e){
            throw new Error(e);
        }
    }

    const getAddress = (location) =>{
        let address;

        switch (location) {
            case 1:
                address = <span>Центр «Профессии&nbsp;будущего» ул.&nbsp;Щепкина,&nbsp;38c1 | м.&nbsp;Проспект&nbsp;Мира</span>;
                break;
            case 2:
                address = <span>Учебный&nbsp;центр «Профессионал» Рязанский&nbsp;проспект,&nbsp;7 | м.&nbsp;Нижегородская</span>;
                break;
            case 3:
                address = <span>Центр&nbsp;«Моя&nbsp;карьера» ул.&nbsp;Сергия&nbsp;Радонежского,&nbsp;1c1 | м.&nbsp;Римская</span>;
                break;
            case 4:
                address = <span>Флагманский&nbsp;центр «Моя&nbsp;работа» ул.&nbsp;Шаболовка,&nbsp;д.48 | м.&nbsp;Шаболовская</span>;
                break;
            default:
                address = <span></span>;
        }
        return address;
    }

    const onSubmit = async (values) => {
        const person_id = uuidv4();

        if (education === 'Студент ссуза / вуза' || education === 'Выпускник ссуза / вуза (не более 1 года с момента окончания)'){
            values['educationDetails'] = educationDetails;
            values['graduation_year'] = format(grYear, 'yyyy');
        } else{
            delete values['specialization'];
            delete values['institution'];
            delete values['graduation_year'];
        }

        const birthday = selectedDate.split('/');

        const data = {'uid': person_id, ...values, 'location_id': placeForLink, 'birthday': birthday[1] + '.' + birthday[0] + '.' + birthday[2], 'education': education};

        if((education && education !== 'Студент ссуза / вуза') || educationDetails){
            await request(data).then(res => {
                if(res.code === 201){
                   if (placeForLink === 5){
                        setTypeModal('success_online')
                    } else {
                        setTypeModal('success_offline')
                    }
                    setAddress(getAddress(placeForLink));

                } else if(res.code === 203){
                    setTypeModal('already_registered');
                } else{
                    alert('Что-то пошло не так. Попробуйте еще раз')
                }
            })
        }
    }

    return(
        <div className="pb-36">
            <div className="flex mx-auto w-full xl:max-w-[1130px] text-white font-bold text-base md:text-3xl my-2 md:my-5 px-4">
                <span className="w-full md:text-center">Форма регистрации</span>
                <span className="font-light text-xl sm:text-4xl cursor-pointer" onClick={() => {
                    setTypeModal(null)
                    setPlace(null)
                }}>x</span>
            </div>
            <div className="flex flex-col gap-4 justify-center bg-orange px-5 py-8 sm:px-10 sm:py-6 sm:pb-12 rounded-xl min-w-full md:w-[760px] lg:w-[900px] xl:min-w-[1100px]">
                <p className="w-full lg:text-center italic text-white text-[10px] lg:text-sm">Все поля помеченные *  обязательны к заполнению</p>
                <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                    <div className="flex flex-col w-full gap-3 lg:gap-7">
                        <div className="flex gap-2 items-center mt-4 lg:my-4 xl:max-w-[1130px]">
                            <p className="text-white text-sm md:text-lg lg:text-[28px] shrink-0 font-semibold">Выберите
                                локацию *</p>
                            <Line color="white"/>
                        </div>
                        <div className="flex flex-col w-full gap-5 md:gap-8">
                            <div className="flex flex-col xl:flex-row  gap-5 md:gap-9 w-full">
                                <div className="xl:w-1/2 flex gap-3 sm:gap-5 items-center text-[9px] sm:text-base md:text-xl">
                                    <input
                                        id="place1"
                                        defaultChecked={placeForLink === 1}
                                        type="radio"
                                        onClick={() => setPlaceForLink(1)}
                                        value="Центр «Профессии будущего» ул. Щепкина, 38c1 | м. Проспект Мира"
                                        className="cursor-pointer flex-shrink-0 appearance-none w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 outline-none rounded-full border-[0.1rem] sm:border-2 border-white bg-transparent items-center justify-center flex checked:text-white checked:before:content-[''] checked:before:bg-white before:rounded-full checked:before:w-[0.7rem] before:h-[0.7rem] md:checked:before:w-4 md:checked:before:h-4 checked:font-extrabold before:flex before:justify-center before:items-center "
                                        {...register("location", {required: true})}
                                    />
                                    <label htmlFor="place1"
                                           className="cursor-pointer text-white font-medium text-sm lg:text-lg leading-6"><span
                                        className="font-extrabold">Центр&nbsp;«Профессии&nbsp;будущего»</span><br/>ул.&nbsp;Щепкина,&nbsp;38c1 | м.&nbsp;Проспект&nbsp;Мира</label>
                                </div>
                                <div className="xl:w-1/2 flex gap-3 sm:gap-5 items-center text-[9px] sm:text-base md:text-xl">
                                    <input
                                        id="place3"
                                        type="radio"
                                        defaultChecked={placeForLink === 3}
                                        onClick={() => setPlaceForLink(3)}
                                        value="Центр «Моя карьера» ул. Сергия Радонежского, 1c1 | м. Римская"
                                        className="cursor-pointer flex-shrink-0 appearance-none w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 outline-none rounded-full border-[0.1rem] sm:border-2 border-white bg-transparent items-center justify-center flex checked:text-white checked:before:content-[''] checked:before:bg-white before:rounded-full checked:before:w-[0.7rem] before:h-[0.7rem] md:checked:before:w-4 md:checked:before:h-4 checked:font-extrabold before:flex before:justify-center before:items-center "
                                        {...register("location", {required: true})}
                                    />
                                    <label htmlFor="place3"
                                           className="cursor-pointer text-white font-medium text-sm lg:text-lg leading-6"><span
                                        className="font-extrabold">Центр&nbsp;«Моя&nbsp;карьера» </span><br/>ул.&nbsp;Сергия
                                        Радонежского, 1c1 | м.&nbsp;Римская</label>
                                </div>
                            </div>
                            <div className="flex flex-col xl:flex-row gap-5 md:gap-9 w-full">
                                <div className="xl:w-1/2 flex gap-3 sm:gap-5 items-center text-[9px] sm:text-base md:text-xl">
                                    <input
                                        id="place2"
                                        type="radio"
                                        defaultChecked={placeForLink === 2}
                                        onClick={() => setPlaceForLink(2)}
                                        value="Учебный центр «Профессионал» Рязанский проспект, 7 | м. Нижегородская"
                                        className="cursor-pointer flex-shrink-0 appearance-none w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 outline-none rounded-full border-[0.1rem] sm:border-2 border-white bg-transparent items-center justify-center flex checked:text-white checked:before:content-[''] checked:before:bg-white before:rounded-full checked:before:w-[0.7rem] before:h-[0.7rem] md:checked:before:w-4 md:checked:before:h-4 checked:font-extrabold before:flex before:justify-center before:items-center "
                                        {...register("location", {required: true})}
                                    />
                                    <label htmlFor="place2"
                                           className="cursor-pointer text-white font-medium text-sm lg:text-lg leading-6"><span
                                        className="font-extrabold">Учебный&nbsp;центр «Профессионал»</span><br/>Рязанский&nbsp;проспект,&nbsp;7
                                        | м.&nbsp;Нижегородская</label>
                                </div>

                                <div className="xl:w-1/2 flex gap-3 sm:gap-5 items-center text-[9px] sm:text-base md:text-xl">
                                    <input
                                        id="place4"
                                        type="radio"
                                        defaultChecked={placeForLink === 4}
                                        onClick={() => setPlaceForLink(4)}
                                        value="Флагманский центр «Моя работа» ул. Шаболовка, д.48 | м. Шаболовская"
                                        className="cursor-pointer flex-shrink-0 appearance-none w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 outline-none rounded-full border-[0.1rem] sm:border-2 border-white bg-transparent items-center justify-center flex checked:text-white checked:before:content-[''] checked:before:bg-white before:rounded-full checked:before:w-[0.7rem] before:h-[0.7rem] md:checked:before:w-4 md:checked:before:h-4 checked:font-extrabold before:flex before:justify-center before:items-center "
                                        {...register("location", {required: true})}
                                    />
                                    <label htmlFor="place4"
                                           className="cursor-pointer text-white font-medium text-sm lg:text-lg leading-6"><span
                                        className="font-extrabold">Флагманский&nbsp;центр «Моя&nbsp;работа»<br/></span>ул.&nbsp;Шаболовка,
                                        д.48 | м.&nbsp;Шаболовская</label>
                                </div>
                            </div>
                            <div className="flex gap-3 sm:gap-5 items-center text-[9px] sm:text-base md:text-xl">
                                <input
                                    id="place5"
                                    type="radio"
                                    defaultChecked={placeForLink === 5}
                                    onClick={() => setPlaceForLink(5)}
                                    value="Онлайн-площадка"
                                    className="cursor-pointer flex-shrink-0 appearance-none w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 outline-none rounded-full border-[0.1rem] sm:border-2 border-white bg-transparent items-center justify-center flex checked:text-white checked:before:content-[''] checked:before:bg-white before:rounded-full checked:before:w-[0.7rem] before:h-[0.7rem] md:checked:before:w-4 md:checked:before:h-4 checked:font-extrabold before:flex before:justify-center before:items-center "
                                    {...register("location", {required: true})}
                                />
                                <label htmlFor="place5"
                                       className="cursor-pointer text-white text-sm lg:text-lg leading-6 font-extrabold">Онлайн-площадка</label>
                            </div>
                        </div>

                        <div className="flex gap-2 items-center my-4 xl:max-w-[1130px]">
                            <p className="text-white text-sm md:text-lg lg:text-[28px] shrink-0 font-semibold">Контактная
                                информация</p>
                            <Line color="white"/>
                        </div>
                        <div className="flex flex-col w-full gap-4 md:gap-7">
                            <div className="flex flex-col xl:flex-row justify-between gap-4 md:gap-7 w-full">
                                <input
                                    className="flex w-full bg-transparent border-[1px] border-white rounded-2xl p-3.5 sm:px-6 sm:py-5 text-white outline-none text-sm sm:text-xl md:text-2xl"
                                    {...register("surname", {required: true})}
                                    placeholder="Фамилия*"
                                />
                                <input
                                    className="flex w-full bg-transparent border-[1px] border-white rounded-2xl p-3.5 sm:px-6 sm:py-5 text-white outline-none text-sm sm:text-xl md:text-2xl"
                                    {...register("firstname", {required: true})}
                                    placeholder="Имя*"
                                />

                            </div>
                            <div className="flex flex-col xl:flex-row justify-between gap-4 md:gap-7 w-full">
                                <input
                                    className="flex !w-full xl:w-1/2 bg-transparent border-[1px] border-white rounded-2xl p-3.5 sm:px-6 sm:py-5 text-white outline-none text-sm sm:text-xl md:text-2xl"
                                    {...register("patronymic", {required: false})}
                                    placeholder="Отчество"
                                />
                                <div className="flex !w-full xl:w-1/2">
                                    <DatePicker
                                        className="flex w-full bg-transparent border-[1px] border-white rounded-2xl p-3.5 sm:px-6 sm:py-5 text-white outline-none text-sm sm:text-xl md:text-2xl"
                                        selected={selectedDate}
                                        onChange={(val) => setSelectedDate(val ? moment(val).format('L') : val)}
                                        dateFormat="dd.MM.yyyy"
                                        locale="ru"
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        customInput={<MaskedInput/>}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col xl:flex-row justify-between gap-4 md:gap-7 w-full">
                                <InputMask
                                    type="text"
                                    mask={'+7 (999) 999-99-99'}
                                    alwaysShowMask={false}
                                    placeholder="Телефон*"
                                    inputMode="numeric"
                                    {...register("phone", {required: true})}
                                    className="flex w-full bg-transparent border-[1px] border-white rounded-2xl p-3.5 sm:px-6 sm:py-5 text-white outline-none text-sm sm:text-xl md:text-2xl"
                                />
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="E-mail*"
                                    {...register("email", {required: true})}
                                    className="flex w-full bg-transparent border-[1px] border-white rounded-2xl p-3.5 sm:px-6 sm:py-5 text-white outline-none text-sm sm:text-xl md:text-2xl"
                                    autoComplete="off"/>
                            </div>
                            <div className="flex flex-col xl:flex-row justify-between gap-4 md:gap-7 w-full">
                                <input
                                    className="flex w-full bg-transparent border-[1px] border-white rounded-2xl p-3.5 sm:px-6 sm:py-5 text-white outline-none text-sm sm:text-xl md:text-2xl"
                                    {...register("resume", {required: false})}
                                    placeholder="Ссылка на резюме"
                                />
                                <input
                                    className="flex w-full bg-transparent border-[1px] border-white rounded-2xl p-3.5 sm:px-6 sm:py-5 text-white outline-none text-sm sm:text-xl md:text-2xl"
                                    {...register("vacancy", {required: false})}
                                    placeholder="Какую вакансию рассматриваю"
                                />
                            </div>
                        </div>

                        <div className="flex gap-2 items-center my-4 xl:max-w-[1130px]">
                            <p className="text-white text-base md:text-lg lg:text-[28px] font-semibold shrink-0">Вы
                                работаете? *</p>
                            <Line color="white"/>
                        </div>
                        <div className="flex gap-[8.5rem] md:gap-[9rem] lg:-gap-14 xl:gap-[16rem] w-full lg:w-fit">
                            <div className="flex gap-3 sm:gap-5 items-center text-[9px] sm:text-base md:text-xl">
                                <input
                                    id="status_work1"
                                    type="radio"
                                    value="Работаю"
                                    className="cursor-pointer flex-shrink-0 appearance-none w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 outline-none rounded-full border-[0.1rem] sm:border-2 border-white bg-transparent items-center justify-center flex checked:text-white checked:before:content-[''] checked:before:bg-white before:rounded-full checked:before:w-[0.7rem] before:h-[0.7rem] md:checked:before:w-4 md:checked:before:h-4 checked:font-extrabold before:flex before:justify-center before:items-center "
                                    {...register("status_work", {required: true})}
                                />
                                <label htmlFor="status_work1"
                                       className="cursor-pointer text-white font-extrabold text-xs md:text-sm lg:text-lg leading-6">Да</label>
                            </div>
                            <div className="flex gap-3 sm:gap-5 items-center text-[9px] sm:text-base md:text-xl">
                                <input
                                    id="status_work2"
                                    type="radio"
                                    value="Не работаю"
                                    className="cursor-pointer flex-shrink-0 appearance-none w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 outline-none rounded-full border-[0.1rem] sm:border-2 border-white bg-transparent items-center justify-center flex checked:text-white checked:before:content-[''] checked:before:bg-white before:rounded-full checked:before:w-[0.7rem] before:h-[0.7rem] md:checked:before:w-4 md:checked:before:h-4 checked:font-extrabold before:flex before:justify-center before:items-center "
                                    {...register("status_work", {required: true})}
                                />
                                <label htmlFor="status_work2"
                                       className="cursor-pointer text-white font-extrabold text-xs md:text-sm lg:text-lg leading-6 shrink-0">Нет</label>
                            </div>
                        </div>

                        <div className="flex gap-2 items-center my-4 xl:max-w-[1130px]">
                            <p className="text-white text-base md:text-lg lg:text-[28px] font-semibold sm:shrink-0">Вы
                                зарегистрированы в службе занятости?&nbsp;*</p>
                            <Line color="white"/>
                        </div>
                        <div className="flex gap-[8.5rem] md:gap-[9rem] lg:-gap-14 xl:gap-[16rem] w-full lg:w-fit">
                            <div className="flex gap-3 sm:gap-5 items-center text-[9px] sm:text-base md:text-xl">
                                <input
                                    id="status_registration1"
                                    type="radio"
                                    value="Зарегистрирован в cлужбе занятости"
                                    className="cursor-pointer flex-shrink-0 appearance-none w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 outline-none rounded-full border-[0.1rem] sm:border-2 border-white bg-transparent items-center justify-center flex checked:text-white checked:before:content-[''] checked:before:bg-white before:rounded-full checked:before:w-[0.7rem] before:h-[0.7rem] md:checked:before:w-4 md:checked:before:h-4 checked:font-extrabold before:flex before:justify-center before:items-center "
                                    {...register("status_registration", {required: true})}
                                />
                                <label htmlFor="status_registration1"
                                       className="flex flex-col md:flex-row md:gap-1 cursor-pointer text-white font-extrabold text-xs md:text-sm lg:text-lg shrink-0">Да</label>
                            </div>
                            <div className="flex gap-3 sm:gap-5 items-center text-[9px] sm:text-base md:text-xl">
                                <input
                                    id="status_registration2"
                                    type="radio"
                                    value="Не зарегистрирован в cлужбе занятости"
                                    className="cursor-pointer flex-shrink-0 appearance-none w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 outline-none rounded-full border-[0.1rem] sm:border-2 border-white bg-transparent items-center justify-center flex checked:text-white checked:before:content-[''] checked:before:bg-white before:rounded-full checked:before:w-[0.7rem] before:h-[0.7rem] md:checked:before:w-4 md:checked:before:h-4 checked:font-extrabold before:flex before:justify-center before:items-center "
                                    {...register("status_registration", {required: true})}
                                />
                                <label htmlFor="status_registration2"
                                       className="cursor-pointer text-white font-extrabold text-xs md:text-sm lg:text-lg leading-6">Нет</label>
                            </div>
                        </div>
                        <div className="flex gap-2 items-center mt-4 xl:max-w-[1130px]">
                            <p className="text-white text-base md:text-lg lg:text-[28px] font-semibold sm:shrink-0">Ваше образование*</p>
                            <Line color="white"/>
                        </div>
                        <div>
                            <Select
                                options={["Учусь в школе", "Студент ссуза / вуза", "Выпускник ссуза / вуза (не более 1 года с момента окончания)", "Не отношусь к данным категориям"]}
                                setEducation={setEducation}
                                value="Статус образования *"
                                label="Статус образования *"
                                setEducationDetails={setEducationDetails}
                                education={education}
                            />
                        </div>

                        {education === 'Студент ссуза / вуза' || education === 'Выпускник ссуза / вуза (не более 1 года с момента окончания)' ? (
                            <div className="flex flex-col lg:flex-row w-full gap-3 md:gap-7 relative md:mt-4 lg:mt-0">
                                <div className="flex flex-col gap-3 md:gap-7 w-full">
                                    <SelectDetail
                                        options={education === 'Студент ссуза / вуза' ? ['Студент ссуза', 'Студент вуза'] : ['Выпускник ссуза', 'Выпускник вуза']}
                                        value={`${educationDetails ? educationDetails : "Ссуз / вуз *"} `}
                                        setEducationDetails={setEducationDetails}
                                        education={education}
                                    />
                                    <input
                                        className="flex w-full bg-transparent border-[1px] border-white rounded-2xl p-3.5 sm:px-6 sm:py-5 text-white outline-none text-sm sm:text-xl md:text-2xl"
                                        {...register("institution", {required: true})}
                                        placeholder="Наименование учреждения *"
                                    />
                                </div>

                                <div className="flex flex-col gap-3 md:gap-7 w-full">
                                    <input
                                        type="text"
                                        name="specialization"
                                        id="specialization"
                                        placeholder="Специализация *"
                                        {...register("specialization", {required: true})}
                                        className="flex w-full bg-transparent border-[1px] border-white rounded-2xl p-3.5 sm:px-6 sm:py-5 text-white outline-none text-sm sm:text-xl md:text-2xl"
                                        autoComplete="off"/>
                                    <div className="relative">
                                        <button
                                            className="relative flex w-full bg-transparent border-[1px] border-white rounded-2xl p-3.5 sm:px-6 sm:py-5 text-white outline-none text-sm sm:text-xl md:text-2xl"
                                            onClick={handleClick}>
                                            {grYear ? format(grYear, "yyyy") : 'Год выпуска*'}
                                        </button>
                                        {isOpen && (
                                            <div className="absolute">
                                                <DatePicker
                                                    className="flex w-full bg-transparent border-[1px] border-white rounded-2xl p-3.5 sm:px-6 sm:py-5 text-white outline-none text-sm sm:text-xl md:text-2xl"
                                                    minDate="1900"
                                                    maxDate="2099"
                                                    selected={grYear}
                                                    onChange={handleChange}
                                                    showYearPicker
                                                    dateFormat="yyyy"
                                                    inline
                                                />
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className="flex gap-2 items-center my-4 xl:max-w-[1130px]">
                            <p className="text-white text-base md:text-lg lg:text-[28px] font-semibold shrink-0">Есть ли
                                у вас инвалидность? *</p>
                            <Line color="white"/>
                        </div>
                        <div className="flex gap-[8.5rem] md:gap-[9rem] lg:-gap-14 xl:gap-[16rem]">
                            <div className="flex gap-3 sm:gap-5 items-center text-[9px] sm:text-base md:text-xl">
                                <input
                                    id="invalid_true"
                                    type="radio"
                                    value="Да"
                                    className="cursor-pointer flex-shrink-0 appearance-none w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 outline-none rounded-full border-[0.1rem] sm:border-2 border-white bg-transparent items-center justify-center flex checked:text-white checked:before:content-[''] checked:before:bg-white before:rounded-full checked:before:w-[0.7rem] before:h-[0.7rem] md:checked:before:w-4 md:checked:before:h-4 checked:font-extrabold before:flex before:justify-center before:items-center "
                                    {...register("invalid", {required: true})}
                                />
                                <label htmlFor="invalid_true"
                                       className="cursor-pointer text-white font-extrabold text-xs md:text-sm lg:text-lg leading-6">Да</label>
                            </div>
                            <div className="flex gap-3 sm:gap-5 items-center text-[9px] sm:text-base md:text-xl">
                                <input
                                    id="invalid_false"
                                    type="radio"
                                    value="Нет"
                                    className="cursor-pointer flex-shrink-0 appearance-none w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 outline-none rounded-full border-[0.1rem] sm:border-2 border-white bg-transparent items-center justify-center flex checked:text-white checked:before:content-[''] checked:before:bg-white before:rounded-full checked:before:w-[0.7rem] before:h-[0.7rem] md:checked:before:w-4 md:checked:before:h-4 checked:font-extrabold before:flex before:justify-center before:items-center "
                                    {...register("invalid", {required: true})}
                                />
                                <label htmlFor="invalid_false"
                                       className="cursor-pointer text-white font-extrabold text-xs md:text-sm lg:text-lg leading-6">Нет</label>
                            </div>
                        </div>

                        <div className="flex gap-3 sm:gap-5 items-center text-[10px] md:text-base lg:text-xl mt-4">
                            <input
                                type="checkbox"
                                name="agreement"
                                id="agreement"
                                {...register("agreement", {required: true})}
                                className="flex-shrink-0 appearance-none w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 outline-none rounded-md sm:rounded-lg border-[0.1rem] sm:border-2 border-white bg-transparent checked:bg-white checked:before:content-['✔'] before:flex before:justify-center before:items-center before:h-full"
                            />
                            <label htmlFor="agreement" className="text-white">Принимаю <a href="/?page=policy"
                                                                                          target="_blank"
                                                                                          className="text-dark">условия
                                обработки персональных данных *</a></label>
                        </div>
                        <div className="flex gap-3 sm:gap-5 items-center text-[10px] md:text-base lg:text-xl mt-2">
                            <input
                                type="checkbox"
                                name="agreementEmail"
                                id="agreementEmail"
                                defaultChecked
                                {...register("agreementEmail", {required: false})}
                                className="flex-shrink-0 appearance-none w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 outline-none rounded-md sm:rounded-lg border-[0.1rem] sm:border-2 border-white bg-transparent checked:bg-white checked:before:content-['✔'] before:flex before:justify-center before:items-center before:h-full"
                            />
                            <label htmlFor="agreementEmail" className="text-white">Я согласен(-на) на получение
                                e-mail-рассылок</label>
                        </div>

                        <button
                            type="submit"
                            disabled={!isValid || !isDirty || !selectedDate || !education || (education === 'Студент ссуза / вуза' && (!educationDetails || !grYear)) || (education === 'Выпускник ссуза / вуза (не более 1 года с момента окончания)' && (!educationDetails || !grYear))}
                            className="disabled:text-gray-400 mt-6 flex w-full bg-white text-dark rounded-xl justify-center items-center text-base sm:text-xl md:text-[27px] font-bold py-3 md:py-7">
                            Отправить
                        </button>

                    </div>
                </form>
            </div>

        </div>
    )
}
export default Form;



