const CareerCentre = ({classNames}) => {
    return (
        <svg className={`${classNames} w-auto`} viewBox="0 0 81 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.7916 55.8468C43.1404 55.8468 55.5831 43.3993 55.5831 28.0445C55.5831 12.6897 43.1404 0.242188 27.7916 0.242188C12.4427 0.242188 0 12.6897 0 28.0445C0 43.3993 12.4427 55.8468 27.7916 55.8468Z" fill="#FBC522"/>
            <path d="M52.4205 55.8468C67.7693 55.8468 80.212 43.3993 80.212 28.0445C80.212 12.6897 67.7693 0.242188 52.4205 0.242188C37.0716 0.242188 24.6289 12.6897 24.6289 28.0445C24.6289 43.3993 37.0716 55.8468 52.4205 55.8468Z" fill="#B4CE4A"/>
            <path d="M58.9725 26.1489L52.3815 36.6317L45.665 26.1489L44.0015 36.4434H39.7958L43.3424 16.6076H44.4095L52.3815 29.5071L60.4162 16.6076H61.4519L65.0299 36.4434H60.7928L58.9725 26.1489Z" fill="white"/>
        </svg>
    )
}
export default CareerCentre;
