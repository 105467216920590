const CentreFuture = ({classNames}) => {
    return (
        <svg className={`${classNames} w-auto`} viewBox="0 0 81 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.7916 55.6047C43.1404 55.6047 55.5831 43.1571 55.5831 27.8023C55.5831 12.4475 43.1404 0 27.7916 0C12.4427 0 0 12.4475 0 27.8023C0 43.1571 12.4427 55.6047 27.7916 55.6047Z" fill="#804E35"/>
            <path d="M52.4205 55.6047C67.7693 55.6047 80.212 43.1571 80.212 27.8023C80.212 12.4475 67.7693 0 52.4205 0C37.0716 0 24.6289 12.4475 24.6289 27.8023C24.6289 43.1571 37.0716 55.6047 52.4205 55.6047Z" fill="#EC6C2C"/>
            <path d="M59.2498 26.3979L52.6588 36.8807L45.9423 26.3979L44.2789 36.6924H40.0732L43.6198 16.8567H44.6869L52.6588 29.7562L60.6936 16.8567H61.7293L65.3072 36.6924H61.0702L59.2498 26.3979Z" fill="white"/>
        </svg>
    )
}
export default CentreFuture;
