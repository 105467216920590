import {useEffect, useState} from "react";
import Footer from "../Footer";
import FirstBlock from "../FirstBlock";
import SecondBlock from "../SecondBlock";
import Modal from "../Modal/Modal";


const MainPage = () => {
    const [placeId, setPlace] = useState(null);
    const [typeModal, setTypeModal] = useState(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);

        if(queryParams.get('link') === 'register'){
            setTypeModal('form')
        }
    }, []);

    return (
        <div>
            <div className={`${typeModal ? 'overflow-y-hidden w-screen fixed h-screen' : ''}`}>
                <div className="bg-dark">
                    <FirstBlock setTypeModal={setTypeModal} setPlace={setPlace}/>
                    <SecondBlock setTypeModal={setTypeModal} setPlace={setPlace} placeId={placeId}/>
                </div>
                <Footer/>
            </div>
            {typeModal ? <Modal typeModal={typeModal} setTypeModal={setTypeModal} placeId={placeId} setPlace={setPlace}/> : null}
        </div>
    );
}

export default MainPage;
