import PlaceListItem from "./PlaceListItem";
import {EducationCentre, CentreFuture, CareerCentre, Shabolovka} from "../icons";
import React, {useEffect, useState} from "react";
import Details from "./Details";
import Folder from "../../../assets/Folder.png";
import Link from "../../Common/Link/Link";

const iconClassNames = 'h-[17px] md:h-[23px]';

const placeList = [
    {id: 1, icon: <CentreFuture classNames={iconClassNames}/>, place: <span className="leading-4 sm:leading-7 lg:leading-[30px] xl:leading-[35px] shrink-0">Центр «Профессии будущего»</span>, address: <span>ул. Щепкина, 38c1</span>, station: 'Проспект Мира'},
    {id: 3, icon: <CareerCentre classNames={iconClassNames}/>, place: <span className="leading-4 sm:leading-7 lg:leading-[30px] xl:leading-[35px]">Центр «Моя карьера» </span>, address: <span>ул. Сергия Радонежского, 1c1</span>, station: 'Римская'},
    {id: 2, icon: <EducationCentre classNames={iconClassNames}/>, place: <span className="leading-4 sm:leading-7 lg:leading-[30px] xl:leading-[35px]">Учебный центр «Профессионал»</span>, address: <span>Рязанский проспект, 7</span>, station: 'Нижегородская'},
    {id: 4, icon: <Shabolovka classNames={iconClassNames}/>, place: <span className="leading-4 sm:leading-5 lg:leading-[30px] xl:leading-[35px]">Флагманский центр «Моя&nbsp;работа»</span>, address: <span>ул. Шаболовка д. 48</span>, station: 'Шаболовская'},
]

const PlaceList = ({setTypeModal, setPlace, placeId}) => {
    const [openPlace, setOpenPlace] = useState(null);
    const [det, setDet] = useState(null);

    useEffect(() => {
        if(placeId){
            if (placeId === 5){
                setOpenPlace({})
                setDet(document.querySelector("#details"))
            } else {
                setOpenPlace(placeList.filter(item => item.id === placeId)[0]);
                setDet(document.querySelector("#details"))
            }
            if(det){
                det.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }
    }, [placeId, setTypeModal, det])

    return (
        <div className="relative">
            <div className="mt-8 md:mt-14 grid grid-cols-1 lg:grid-cols-2 relative gap-3 md:gap-5 w-full">
                {placeList.map(place =>
                    <div key={place.id}>
                        <PlaceListItem {...place}
                                       placeId={placeId}
                                       setPlace={setPlace}
                        />
                        <div id="details">
                            {placeId && placeId === place.id && openPlace ?
                                <div className="flex lg:hidden w-full">
                                    <Details {...openPlace} setTypeModal={setTypeModal} setPlace={setPlace} placeId={placeId}/>
                                </div>
                                : null}
                        </div>

                    </div>
                )}
            </div>
            <div className="flex justify-center w-full mt-6 md:mt-14">
                <Link color={placeId === 5 ? 'greenActive' : 'green'} text='Онлайн-площадка' setTypeModal={setTypeModal} setPlace={setPlace} place={placeId === 5 ? null : 5} />
            </div>
            <div id="details">
                {placeId && openPlace ?
                    <div className={`${placeId !== 5 ? 'lg:flex hidden ' : 'flex'} w-full`}>
                        <Details {...openPlace} setTypeModal={setTypeModal} setPlace={setPlace} placeId={placeId}/>
                    </div>
                    : null}
            </div>


            <div className={`${placeId ? 'hidden lg:flex' : 'hidden'} absolute bottom-0 right-0 w-[1300px]`}>
                <img
                    className={`z-10 lg:w-[1000px] xl:w-[1300px] lg:translate-y-[3rem] xl:translate-y-[10rem] lg:!translate-x-[68rem] xl:!translate-x-[63rem] 2xl:!translate-x-[63rem]`}
                    src={Folder} alt=""
                />
            </div>
        </div>

    )
}

export default PlaceList;
