import Arrow from "../../assets/icons/Arrow";
import Link from "../Common/Link/Link";
import Gear5 from "../../assets/Gear5.png";
import Gear4 from "../../assets/Gear4.png";
import Blur4 from "../../assets/Blur4.png";
import Blur5 from "../../assets/Blur5.png";

import PlaceList from "./components/PlaceList";

const SecondBlock = ({setTypeModal, setPlace, placeId}) => {
  return (
      <div className={`${placeId ? 'pb-10 xl:pb-2' : 'pb-8' } relative w-full h-full text-white`}>
          <div className="relative max-w-[1400px] mx-auto px-5 sm:px-8 md:px-14 py-7 md:py-14">
              <div className={`absolute top-0 right-0`}>
                  <img className={`z-10 relative w-[400px] sm:w-[450px] md:w-[550px] -translate-y-[5rem] xl:translate-y-[2rem] translate-x-[17rem] sm:translate-x-80 md:!translate-x-96 lg:translate-x-44`}
                       src={Gear5} alt=""
                  />
              </div>
              <div className="z-2 absolute top-[0px] left-[-120px] sm:left-[100px] lg:hidden">
                <img
                  className={`relative min-w-[526px] min-h-[490px] md:w-[726px] md:h-[690] -translate-y-[5rem] xl:translate-y-[2rem] translate-x-[17rem] sm:translate-x-80 md:!translate-x-96 lg:translate-x-44`}
                  src={Blur5}
                  alt=""
                />
            </div>
              <div className={`flex items-center gap-3 md:gap-5 w-full`}>
                  <div className="text-sm sm:text-xl md:text-[40px] font-bold leading-4 md:leading-[40px] flex-shrink-0">
                      Площадки
                  </div>
                  <Arrow color="white" className={`md:mt-2 flex z-50 w-full min-w-screen`}/>
              </div>
              <PlaceList setTypeModal={setTypeModal} setPlace={setPlace} placeId={placeId}/>
              <div className={`${placeId ? 'hidden' : 'flex'} flex-col items-center justify-center gap-6 sm:gap-7 mt-10 md:mt-20 md:gap-10 md:justify-start !z-50 md:z-10`}>
                  <p className="text-white text-sm sm:text-xl md:text-[32px] font-bold text-center leading-4 md:leading-[40px] max-w-[80%] md:max-w-[90%] lg:max-w-[70%]">Вход на мероприятие по&nbsp;предварительной регистрации</p>
                  <Link color="red" setTypeModal={setTypeModal} type="form" text="Регистрация" className="relative !z-50"/>
              </div>
              <div className="absolute bottom-0 left-0 z-10" id="register">
                  <img className={`relative z-10 w-[250px] sm:w-[300px] md:w-[400px] xl:w-[510px] translate-y-40 sm:translate-y-44 md:translate-y-[14rem] xl:translate-y-[14rem] -translate-x-20 sm:-translate-x-20 md:!-translate-x-24 lg:!-translate-x-[7rem] 2xl:translate-x-0`}
                       src={Blur4} alt=""
                  />
              </div>
              <div className="absolute bottom-0 left-0 w-[150px] sm:w-[210px] md:w-[300px] xl:w-[365px]">
                  <img className={`relative z-50 w-[150px] sm:w-[210px] md:w-[300px] xl:w-[365px] translate-y-20 sm:translate-y-28 md:translate-y-[10rem] xl:translate-y-[8rem] -translate-x-14 sm:-translate-x-16 md:!-translate-x-24 lg:!-translate-x-[6rem] 2xl:translate-x-0`}
                       src={Gear4} alt=""
                  />
              </div>
          </div>
      </div>
  )
}

export default SecondBlock;
