export const scheduleListFirst = [
    {
        id: 1,
        title: 'Тренинги и лекции',
        schedule: [
            {
                id: 1,
                subtitle: 'Тренинг «Переход в новую сферу: ключи к успешной адаптации»',
            },
            {
                id: 2,
                subtitle: 'Тренинг «Карьерный стендап»',
            },
            {
                id: 3,
                subtitle: 'Тренинг «Управление впечатлением»',
            },
        ],
    },
    {
        id: 2,
        title: 'Профориентационное нейротестирование',
        schedule: [
            {
                id: 1,
                subtitle: 'Анализ электрической активности мозга на склонность к тому или иному способу обучения',
            },
        ]
    },
    {
        id: 3,
        title: 'Деловые игры',
        schedule: [
            {
                id: 1,
                subtitle: 'Деловая игра «Лабиринт эмоций»',
            },
            {
                id: 2,
                subtitle: 'Деловая игра «Коробочное решение»',
            },
            {
                 id: 3,
                 subtitle: 'Деловая игра «Трансформация карьеры: от препятствия к реализации»',
            },
        ],
    },
    {
        id: 4,
        time: '10:00‒16:00',
        title: 'Мастер-классы от партнеров',
        schedule: [
            {
                id: 1,
                subtitle: 'Шеф-повара известных московских компаний проведут для вас вкусные мастер-классы, где вы откроете для себя новые вкусы и интересные рецепты',
            },
        ]
    },
]


export const scheduleListSecond = [
    {
        id: 1,
        title: 'Тренинги и лекции',
        schedule: [
            {
                id: 1,
                subtitle: 'Мастер-класс «Как составить продающее резюме»',
            },
            {
                id: 2,
                subtitle: 'Мастер-класс «Как пройти собеседование»',
            },
        ],
    },
    {
        id: 2,
        title: 'Мастер-классы',
        schedule: [
            {
                id: 1,
                subtitle: 'Мастерская «Планирование карьеры»',
            },
        ],
    },
    {
        id: 3,
        title: 'Компьютерное тестирование',
        schedule: [
            {
                id: 1,
                subtitle: 'Тест «Карьерные интересы»',
            },
            {
                id: 2,
                subtitle: 'Тест «Профессиональные интересы»',
            },
        ]
    },
]

export const scheduleListThird = [

    {
        id: 1,
        title: 'Тренинги',
        schedule: [
            {
                id: 1,
                subtitle: 'Тренинг «Барьеры в коммуникации»',
            },
            {
                id: 2,
                subtitle: 'Тренинг «Как вас оценивает работодатель»',
            },
            {
                id: 3,
                subtitle: 'Тренинг «Как выбрать своего работодателя»',
            },
            {
                id: 4,
                subtitle: 'Тренинг «Самокоучинг для профессионального роста»',
            },
            {
                id: 5,
                subtitle: 'Тренинг «Карьерные цели и поиск работы»',
            },
            {
                id: 6,
                subtitle: 'Тренинг «Эффективное собеседование»',
            },
            {
                id: 7,
                subtitle: 'Тренинг «Личный бренд»',
            },
            {
                id: 8,
                subtitle: 'Тренинг «Стратегии для адаптации на рабочем месте»',
            },
            {
                id: 9,
                subtitle: 'Тренинг «Как получить предложение о работе»',
            },
            {
                id: 10,
                subtitle: 'Тренинг «Как стать лучшей версией себя»',
            },
            {
                id: 11,
                subtitle: 'Тренинг «Как подготовить эффективное резюме»',
            },
            {
                id: 12,
                subtitle: 'Тренинг «Секреты успешного прохождения собеседования»',
            }
        ],
    },
    {
        id: 2,
        title: 'Деловые игры',
        schedule: [
            {
                id: 1,
                subtitle: 'Деловая игра «Как изменить карьерный путь и найти новую работу»',
            },
            {
                id: 2,
                subtitle: 'Песочная арт-терапия «Уверенное трудоустройство. Как справиться с волнением»',
            },
        ],
    },
    {
        id: 3,
        title: 'Мастер-классы',
        schedule: [
            {
                id: 1,
                subtitle: 'Мастер-класс «Индустрия красоты»',
            },
            {
                id: 2,
                subtitle: 'Мастер-класс «Волшебство эмали»',
            },
            {
                id: 3,
                subtitle: 'Мастер-класс «Роспись пряников и изготовление конфет из сухофруктов»',
            },
            {
                id: 4,
                subtitle: 'Мастер-класс «Карвинг по овощам и фруктам как возможность дополнительного заработка»',
            },
            {
                id: 5,
                subtitle: 'Мастер-класс «Ландшафтный дизайн»',
            },
            {
                id: 6,
                subtitle: 'Мастер-класс «Укладка волос. Прическа легко и быстро»',
            },
        ],
    },
]


export const scheduleListFourth = [

    {
        id: 1,
        title: 'Мероприятия на свежем воздухе',
        schedule: [
            {
                id: 1,
                subtitle: 'Йога: заряжение энергией и укрепление силы воли!',
            },
            {
                id: 2,
                subtitle: 'Телесная практика для снятия эмоциональных зажимов. Стояние на гвоздях',
            },
            {
                id: 3,
                subtitle: 'Медитация «Прикосновение Земли: Исцеляющая Сила Природы»',
            },
            {
                id: 4,
                subtitle: 'Тренинг «Краш-тест от «я – образа» к продуктивной личности»',
            },
            {
                id: 5,
                subtitle: 'Лекция нутрициолога',
            },
            {
                id: 6,
                subtitle: 'Кино-тренинг «Типы личностей»',
            }
        ],
    },
    {
        id: 2,
        title: 'Деловые игры',
        schedule: [
            {
                id: 1,
                subtitle: 'Колесо баланса',
            },
            {
                id: 2,
                subtitle: 'Блиц-диагностика в игровом формате «Пульс вашего бизнеса»',
            },
            {
                id: 3,
                subtitle: '«Территория денег»',
            },
            {
                id: 4,
                subtitle: 'Деловая игра «Формула профессии»',
            },
            {
                id: 5,
                subtitle: 'Бизнес-игра «Семь шагов к успеху»',
            },
            {
                id: 6,
                subtitle: 'Финансовая грамотность',
            },
            {
                id: 7,
                subtitle: 'Тренинг «Как вырастить ребенка гения»',
            },
            {
                id: 8,
                subtitle: 'Fashion-фестиваль «Тренды моды»',
            },
        ],
    },
    {
        id: 3,
        title: 'Мастер-классы',
        schedule: [
            {
                id: 1,
                subtitle: 'Мастер-класс «Создание ботов для быстрых продаж»',
            },
            {
                id: 2,
                subtitle: 'Создание карточки для маркетплейсов и аналитика конкурентов с использованием искусственного интеллекта',
            },
            {
                id: 3,
                subtitle: 'Тайм-менеджмент «Баланс между работой и жизнью: миф или реальность?»',
            },
            {
                id: 4,
                subtitle: 'Нейросети для бизнеса. Возможности и угрозы',
            }
        ],
    },
]


export const webinars = [
    {
        id: 1,
        title: 'Тренинги и лекции',
        schedule: [
            {
                id: 1,
                subtitle: '«Как пережить потерю бизнеса и построить его с нуля»',
            },
            {
                id: 2,
                subtitle: '«Актуальные меры поддержки малого и среднего бизнеса в Москве»',
            },
            {
                id: 3,
                subtitle: '«Строим эффективную бизнес-модель за 1 час»',
            },
            {
                id: 4,
                subtitle: '«Личный бренд: как заработать на своем имени»',
            },
            {
                id: 5,
                subtitle: '«Работай на себя: как быстро найти заработок через платформы»',
            },
            {
                id: 7,
                subtitle: '«Тренды современного рынка труда»',
            },
            {
                id: 8,
                subtitle: '«Как построить карьеру: эффективные стратегии»',
            },
            {
                id: 9,
                subtitle: '«Резюме глазами рекрутера»',
            },
            {
                id: 10,
                subtitle: '«Ключи к эффективной самопрезентации»',
            },
            {
                id: 11,
                subtitle: '«Карьерная прожарка: как пройти стресс-интервью»',
            },
            {
                id: 12,
                subtitle: '«Как влиться в новый коллектив»'
            },
            {
                id: 13,
                subtitle: '«Из найма в бизнес: как побороть свои страхи»'
            },
        ],
    }
]

