import Palms from "../../../assets/palms.png";
import Ties from "../../../assets/ties.png";

const Advantages = () => {
  return (
    <div className="relative w-full">
      <div className="flex flex-row gap-2 md:gap-5 text-white w-[652px] sm:w-[900px] md:w-[1296px] z-30 relative mt-[9px] md:mt-5">
        <div className="flex gap-2 md:gap-5 items-center bg-dark rounded-xl p-3 md:h-[240px] w-1/2 min-w-[320px] sm:w-full md:min-w-[636px] h-[120px]">
          <img
            src={Palms}
            className="w-[60px] sm:w-[80px] md:w-[112px] md:h-[167px] md:ml-3"
            alt=""
          />
          <div className="flex gap-1 sm:gap-2 md:gap-1">
            <span className="text-[46px] sm:text-5xl md:text-[80px] xl:text-[100px] font-extrabold shrink-0"> > </span>
            <div className="flex flex-col  w-full md:h-fit h-full">

              <div className="flex">
                <span className="text-[46px] sm:text-5xl md:text-[80px] xl:text-[100px] font-extrabold shrink-0">
                    3
                </span>
                <span className="text-[9px] sm:text-sm md:text-xl font-semibold leading-3 w-full md:leading-6 pl-2.5 flex items-center">
                    тысяч
                    <br />
                    актуальных
                    <br />
                    вакансий
                </span>
              </div>
            <span className="text-[8px] sm:text-[10px] md:text-sm leading-3 md:leading-5 md:pt-2 w-full -mt-2 sm:mt-0">
              от лучших работодателей из наиболее перспективных отраслей экономики
            </span></div>

          </div>
        </div>
        <div className="flex gap-0 items-center bg-dark rounded-xl p-3 md:h-[240px] min-w-[320px] w-full md:min-w-[636px] h-[120px]">
          <div className="flex gap-2">
            <img
              src={Ties}
              className="w-[60px] sm:w-[80px] md:w-[136px] md:h-[152px]"
              alt=""
            />
            <div className="flex gap-2 items-center">
              <span className="text-[46px] sm:text-5xl md:text-7xl md:text-[80px] xl:text-[100px] font-extrabold shrink-0 md:leading-[110px] ">
                > 100
              </span>
              <div className="flex flex-col">
                <span className="text-[9px] sm:text-sm md:text-xl font-extrabold leading-3 md:leading-6 w-full md:shrink-0">
                  работодателей
                </span>
                <span className="text-[9px] sm:text-sm md:text-xl leading-3 md:leading-5 w-full font-medium">
                  на четырех
                  <br/>
                  офлайн-
                  <br />
                  площадках
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advantages;
