import {
    RZD, Russia, Vasilchuki, Cherkizovo, Metro, Gallery, Auto, BNS, Coffeemania, City, Alfa, Pobeda, Energy, Faberlic, Sreda, Economy
} from "./images";

const partners = [
    {id: 1, src: Metro, alt: 'Мегафон'},
    {id: 2, src: Vasilchuki, alt: 'Васильчуки'},
    {id: 3, src: Russia, alt: 'Россия'},
    {id: 4, src: Gallery, alt: 'Москвариум'},
    {id: 5, src: Cherkizovo, alt: 'Черкизово'},
    {id: 6, src: RZD, alt: 'РЖД'},
    {id: 7, src: Auto, alt: 'Борисов'},
    {id: 8, src: BNS, alt: 'Ашан'},
    {id: 9, src: Coffeemania, alt: 'Эндофарм'},
    {id: 10, src: City, alt: 'Медси'},
    {id: 11, src: Alfa, alt: 'Детский мир'},
    {id: 12, src: Pobeda, alt: 'Самолет'},
    {id: 13, src: Energy, alt: 'Эндофарм'},
    {id: 14, src: Faberlic, alt: 'Медси'},
    {id: 15, src: Sreda, alt: 'Детский мир'},
    {id: 16, src: Economy, alt: 'Самолет'}
]

const Footer = () => {
  return (
      <div className="relative !z-40 bg-white">
          <div className=" max-w-[1400px] mx-auto px-5 sm:px-8 md:px-14 py-8 md:py-14 flex flex-col items-center justify-items-center gap-3 sm:gap-6 md:gap-10 h-fit">
              <p className="text-base sm:text-xl md:text-3xl text-orange font-extrabold">Наши партнеры</p>
              <div className="flex flex-wrap gap-2 sm:gap-5 w-full justify-center 2xl:justify-between items-center">
                  {partners.map(item =>
                      <div key={item.id} className="max-w-[150px] lg:max-w-[305px]">
                          <img src={item.src} alt={item.alt}/>
                      </div>
                  )}
              </div>
          </div>
      </div>
  )
}

export default Footer;
