import "../SecondBlock.scss";

const ScheduleListItem = ({id, subtitle, desc}) => {

    return (
        <li className={`flex schedule_ul__li py-1 md:py-1.5`} key={id}>
            <div className="flex justify-between w-[96%] gap-5 cursor-pointer">
                <div className="flex flex-col w-full">
                    <div className="flex">
                        <span className="font-semibold md:leading-5 py-0.5 md:py-1">{subtitle}</span>
                    </div>
                    <ul className="">
                        <li className="leading-4 lg:leading-6">{desc && desc}</li>
                    </ul>
                </div>
            </div>
        </li>

    )
}
export default ScheduleListItem;
