import React from "react";
import onClickYm from "../../../utils/utils";

const Link = ({className = '', href, color = 'red', setTypeModal, goal, text, setPlace, place, type}) => {
    const colorVar = {
        red: 'bg-orange',
        green: 'bg-green/70',
        greenActive: 'bg-green',
    }
    const borderVar = {
        red: 'border-orange',
        green: 'border-green/70',
        greenActive: 'border-green',
    }

    return (
        <div
            onClick={() => {
                if(setTypeModal){
                    setTypeModal(type);
                    if(setPlace){
                        setPlace(place)
                    }
                }
                onClickYm(goal);
            }}
            className={`${className} z-50 max-w-[560px] cursor-pointer border-solid ${borderVar[color]} border-2 p-2 md:p-4 hover:scale-[1.02] transition duration-150 ease-in-out w-fit rounded-[42px]`}>
            <a type="submit" href={href} className={`${className} flex gap-3 sm:gap-5 items-center justify-between no-underline ${colorVar[color]} text-white rounded-[42px] text-[15px] md:text-xl lg:text-3xl font-medium md:px-[22px] py-0.5 md:py-[5px] px-5`}>
                <p className="whitespace-nowrap montserrat font-extrabold shadow-white">{text}</p>
                <svg className="w-[50px] sm:w-[80px] md:w-[154px]" viewBox="0 0 154 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M152.975 9.80306C153.418 9.35953 153.418 8.64044 152.975 8.19692L145.747 0.969275C145.303 0.525751 144.584 0.525751 144.141 0.969275C143.697 1.4128 143.697 2.13189 144.141 2.57542L150.565 8.99999L144.141 15.4246C143.697 15.8681 143.697 16.5872 144.141 17.0307C144.584 17.4742 145.303 17.4742 145.747 17.0307L152.975 9.80306ZM0.742859 10.1357L76.4571 10.1357L76.4571 7.86428L0.742859 7.86429L0.742859 10.1357ZM76.4571 10.1357L152.171 10.1357L152.171 7.86427L76.4571 7.86428L76.4571 10.1357Z" fill="white"/>
                </svg>
            </a>
        </div>

    )
}

export default Link;
