const Arrow = ({color, className}) => {
    return (
        <>
            {color === 'red' ?
                <svg className={`${className}`} viewBox="0 0 1049 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1048.71 8.70702C1049.1 8.31649 1049.1 7.68333 1048.71 7.2928L1042.34 0.928841C1041.95 0.538317 1041.32 0.538317 1040.93 0.928841C1040.54 1.31937 1040.54 1.95253 1040.93 2.34305L1046.59 7.99991L1040.93 13.6568C1040.54 14.0473 1040.54 14.6805 1040.93 15.071C1041.32 15.4615 1041.95 15.4615 1042.34 15.071L1048.71 8.70702ZM8.74228e-08 9L1048 8.99991L1048 6.99991L-8.74228e-08 7L8.74228e-08 9Z" fill="E44621"/>
                </svg>

                :
                <svg className={`${className}`} viewBox="0 0 1049 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1048.71 8.70702C1049.1 8.31649 1049.1 7.68333 1048.71 7.2928L1042.34 0.928841C1041.95 0.538317 1041.32 0.538317 1040.93 0.928841C1040.54 1.31937 1040.54 1.95253 1040.93 2.34305L1046.59 7.99991L1040.93 13.6568C1040.54 14.0473 1040.54 14.6805 1040.93 15.071C1041.32 15.4615 1041.95 15.4615 1042.34 15.071L1048.71 8.70702ZM8.74228e-08 9L1048 8.99991L1048 6.99991L-8.74228e-08 7L8.74228e-08 9Z" fill="white"/>
                </svg>
            }
        </>

    )
}
export default Arrow;
