import {Interviews, Career, Resume, Consultation, ProfTest} from "../icons";
import Arrow from "../../../assets/icons/Arrow";
import {SwiperPlan} from "./Swiper";
import Blur5 from "../../../assets/Blur5.png";


const events = [
    {id: 1, title: <span className="leading-4 md:leading-8">Карьерные тренинги, мастер-классы и&nbsp;деловые игры</span>, text: <span>от практикующих преподавателей и экспертов рынка</span>, icon: <Career/>},
    {id: 2, title: <span className="leading-4 md:leading-8">Помощь <br/> в составлении продающего резюме</span>, icon: <Resume/>},
    {id: 3, title: <span className="leading-4 md:leading-9">Индивидуальные консультации</span>, text: <span>с карьерными наставниками</span>, icon: <Consultation/>},
    {id: 4, title: <span className="leading-4 md:leading-9">Профориентационное нейротестирование</span>, text: <span>для определения подходящего карьерного направления</span>, icon: <ProfTest/>},
]

const EventCard = ({id, icon, title, text}) => {
    return (
        <div key={id}
             className="relative flex flex-col text-white bg-orange rounded-xl w-full h-full max-h-[215px] p-4 md:p-6">
            <span className="font-extrabold md:text-[27px]s text-sm md:text-[27px] leading-[16px]">{title}</span>
            <span className="text-[8px] md:text-base w-5/6 leading-[11px] md:pb-5" >{text}</span>
            <div className='absolute bottom-2 right-2 md:bottom-6 md:right-5'>{icon}</div>
        </div>
    )
}


const Plan = () => {
    return (
        
        <div className="z-10 relative w-[652px] sm:w-[900px] md:w-[1296px] grid grid-cols-3 col-span-1 gap-x-2 md:gap-x-5">
            
            <div className="col-span-1 flex flex-col justify-between bg-dark rounded-xl text-white p-4 md:p-6 w-full h-full">
                <div className="flex flex-col  ">
                    <span className="font-extrabold text-xs md:text-[27px] leading-[16px] md:leading-9">Собеседования <br/>с работодателями</span>
                    <span className="font-medium text-[8px] leading-[11px] md:text-base w-5/6 ">возможность личного знакомства и быстрого трудоустройства</span>
                </div>
                <Interviews />
            </div>
            <div className="col-span-2 flex gap-3 md:gap-6 z-30 w-full ">
                <div className="flex flex-col gap-2 md:gap-4 w-full h-full">
                    {events.slice(0, 2).map(event => <EventCard key={event.id} {...event}/>)}
                </div>
                <div className="flex flex-col gap-2 md:gap-4 w-full h-full">
                    {events.slice(2, 4).map(event => <EventCard key={event.id} {...event}/>)}
                </div>
            </div>
            {/*<div className="flex lg:hidden">*/}
            {/*    */}
            {/*</div>*/}

        </div>
    )
}

export default Plan;
