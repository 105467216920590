import logo from "../../../assets/logo.png";
import gerb from "../../../assets/gerb.png";
import czn from "../../../assets/czn.png";
import { Line } from "../icons";


const Header = () => {
    return (
        <div className="flex gap-7 md:gap-12 mb-14 md:mb-20 md:justify-start">
            <a href="https://mosfuture.ru/" target="_blank" className="w-[108px] md:w-[174px] self-center">
                <img className="w-full" src={logo} alt="Профессии будущего"/>
            </a>
            <div className="w-[108px] md:w-[135px] self-center">
                <img className="w-full" src={czn} alt="Служба занятости Москвы"/>
            </div>
            <Line/>
            <div className="w-[100px] md:w-[155px] self-center">
                <img className="w-full" src={gerb} alt="Правительство Москвы"/>
            </div>
        </div>
    )
}

export default Header;
