const EducationCentre = ({classNames}) => {
    return (
        <svg className={`${classNames} w-auto`} viewBox="0 0 101 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.8223 56.3067C43.1881 56.3067 55.6445 43.8454 55.6445 28.4737C55.6445 13.1019 43.1881 0.640625 27.8223 0.640625C12.4564 0.640625 0 13.1019 0 28.4737C0 43.8454 12.4564 56.3067 27.8223 56.3067Z" fill="#F1A6AF"/>
            <path d="M48.3359 56.3067C63.7017 56.3067 76.1582 43.8454 76.1582 28.4737C76.1582 13.1019 63.7017 0.640625 48.3359 0.640625C32.9701 0.640625 20.5137 13.1019 20.5137 28.4737C20.5137 43.8454 32.9701 56.3067 48.3359 56.3067Z" fill="#D46FA9"/>
            <path d="M72.9649 56.3067C88.3307 56.3067 100.787 43.8454 100.787 28.4737C100.787 13.1019 88.3307 0.640625 72.9649 0.640625C57.599 0.640625 45.1426 13.1019 45.1426 28.4737C45.1426 43.8454 57.599 56.3067 72.9649 56.3067Z" fill="#6FC4C5"/>
            <path d="M80.3221 26.5786L73.7311 37.0614L67.0146 26.5786L65.3511 36.873H61.1455L64.692 17.0373H65.7591L73.7311 29.9368L81.7658 17.0373H82.8015L86.3795 36.873H82.1425L80.3221 26.5786Z" fill="white"/>
        </svg>
    )
}
export default EducationCentre;
