import './Policy.module.scss';

const Policy = () => {
    return (
        <div className="bg-bgGray circe">
            <div className="relative max-w-[1400px] mx-auto px-8 md:px-14 py-14">
                <p><h1 className="leading-10 font-semibold mb-4">Политика конфиденциальности персональных данных, размещенных на интернет-сайте</h1></p>
                <p>Настоящая Политика конфиденциальности персональных данных, размещенных на интернет-сайтах (далее - Политика конфиденциальности) в информационно-телекоммуникационной сети Интернет по сетевым адресам: https://czn.mos.ru, https://cznmos.ru и https://mosfuture.ru (далее &ndash; Сайт) разработана и применяется в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ &laquo;О персональных данных&raquo; и определяет порядок обработки и меры по обеспечению безопасности, осуществляемые Государственным казенным учреждением города Москвы Центром занятости населения города Москвы (далее &ndash; Оператор) в отношении персональных данных посетителей Сайта (далее &ndash; Пользователь).</p>
                <p>Использование сервисов Сайта означает безоговорочное согласие Пользователя с настоящей Политикой конфиденциальности и указанными в ней условиями обработки его персональных данных; в случае несогласия с этими условиями Пользователь должен воздержаться от использования сервисов Сайта.</p>
                <p>Согласие с настоящей Политикой конфиденциальности, посредством проставления Пользователем &laquo;галочки&raquo; в соответствующей веб-форме, означает предоставление Пользователем согласия на обработку своих персональных данных, а также согласия на получение им информационных материалов, способами, указанными в настоящей Политике конфиденциальности.</p>
                <h1>1. Общие положения</h1>
                <p>1.1. В рамках настоящей Политики конфиденциальности под персональными данными Пользователя понимаются:</p>
                <p>1.1.1. Персональная информация, которую Пользователь предоставляет о себе самостоятельно при регистрации (создании учетной записи) или в процессе использования сервисов Сайта, включая персональные данные Пользователя. Обязательная для предоставления сервисов Сайта информация помечена специальным образом.</p>
                <p>1.1.2. Данные, которые автоматически передаются сервисам Сайта в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные файлов cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к сервисам Сайта), технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к сервисам Сайта, сетевые адреса запрашиваемых страниц и иная подобная информация.</p>
                <p>1.1.3. Настоящая Политика конфиденциальности Оператора в отношении персональных данных Пользователей применяется ко всей информации, которую Оператор может получить о Пользователях во время использования ими Сайта.</p>
                <p>Политика конфиденциальности не распространяется на иные интернет-сайты и не применяется в отношении интернет-сайтов третьих лиц. Оператор не несет ответственность за интернет-сайты третьих лиц, на которые Пользователи могут перейти по ссылкам, доступным на Сайте.</p>
                <p>1.2. Для целей настоящей Политики конфиденциальности понятие Сайт (интернет-сайт), применяется в следующем значении &ndash; совокупность информации, текстов, графических элементов, дизайна, изображений и иных результатов интеллектуальной деятельности, а также программ для ЭВМ, содержащихся в информационной системе, обеспечивающей доступность такой информации в информационно-телекоммуникационной сети Интернет по сетевым адресам https://czn.mos.ru, https://cznmos.ru, https://mosfuture.ru.</p>
                <p>Иные понятия, используемые в настоящей Политике конфиденциальности, применяются в значениях, определенных применимым законодательством Российской Федерации и города Москвы.</p>
                <p>1.3. Действие настоящей Политики конфиденциальности распространяется на все операции, совершаемые Оператором с персональными данными с использованием средств автоматизации или без их использования.</p>
                <h1>2. Основные права и обязанности Оператора и Пользователя</h1>
                <p>2.1. Оператор имеет право:</p>
                <ul>
                    <li>получать от Пользователя достоверные информацию и/или документы, содержащие персональные данные;</li>
                    <li>требовать от Пользователя своевременного уточнения предоставленных персональных данных.</li>
                </ul>
                <p>2.2. Оператор обязан:</p>
                <ul>
                    <li>обрабатывать персональные данные в порядке, установленном действующим законодательством Российской Федерации;</li>
                    <li>обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением предусмотренных настоящей Политикой конфиденциальности;</li>
                    <li>использовать полученную информацию исключительно для целей, указанных в настоящей Политике конфиденциальности;</li>
                    <li>рассматривать обращения Пользователя (его законного представителя) по вопросам обработки персональных данных и давать мотивированные ответы;</li>
                    <li>предоставлять Пользователю (его законному представителю) возможность безвозмездного доступа к его персональным данным;</li>
                    <li>принимать меры по уточнению, уничтожению персональных данных Пользователя в связи с его (его законного представителя) обращением с законными и обоснованными требованиями. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя (его законного представителя) либо уполномоченного органа по защите прав субъектов персональных данных на период проверки в случае выявления недостоверных персональных данных или неправомерных действий;</li>
                    <li>организовывать защиту персональных данных в соответствии с требованиями законодательства Российской Федерации.</li>
                </ul>
                <p>2.3. Пользователи имеют право:</p>
                <ul>
                    <li>на полную информацию об их персональных данных, обрабатываемых Оператором;</li>
                    <li>на доступ к их персональным данным, включая право на получение копии любой записи, содержащей их персональные данные, за исключением случаев, предусмотренных законом;</li>
                    <li>на уточнение своих персональных данных, их блокирование или уничтожение в случаях, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки;</li>
                    <li>на отзыв согласия на обработку персональных данных;</li>
                    <li>на принятие предусмотренных законом мер по защите своих прав;</li>
                    <li>на осуществление иных прав, предусмотренных законодательством Российской Федерации.</li>
                </ul>
                <p>2.4. Пользователи обязаны:</p>
                <ul>
                    <li>предоставлять Оператору только достоверную информацию о персональных данных, необходимую для пользования Сайтом;</li>
                    <li>предоставлять документы, содержащие персональные данные в объеме, необходимом для цели обработки;</li>
                    <li>обновлять, дополнять предоставленную информацию о персональных данных в случае изменения данной информации.</li>
                </ul>
                <p>2.5. Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом Пользователе без согласия последнего, несут ответственность в соответствии с законодательством Российской Федерации.</p>
                <h1>3. Цели обработки персональных данных Пользователей</h1>
                <p>3.1. Оператор обрабатывает персональные данные Пользователей в следующих целях:</p>
                <p>3.1.1. Соблюдения требований законодательства Российской Федерации и города Москвы, локальных актов Оператора.</p>
                <p>3.1.2. Идентификации Пользователя, зарегистрированного на Сайте, для целей реализации сервисов по содействию в его трудоустройстве, организации необходимого профессионального обучения и дополнительного профессионального образования и иных целей.</p>
                <p>3.1.3. Предоставления Пользователю доступа к персонализированным ресурсам Сайта.</p>
                <p>3.1.4. Установления с Пользователем обратной связи, включая направление информационных материалов, уведомлений, запросов, касающихся использования Сайта, реализации сервисов, обработку запросов, заявлений и заявок Пользователя.</p>
                <p>3.1.5. Определения географического положения Пользователя для обеспечения безопасности, предотвращения мошенничества.</p>
                <p>3.1.6. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.</p>
                <p>3.1.7. Создания учетной записи для Пользователя, если Пользователь дал согласие на создание учетной записи.</p>
                <p>3.1.8. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта.</p>
                <p>3.1.9. Сбора статистики и улучшения качества работы Сайта для удобства использования и разработки новых сервисов и услуг.</p>
                <h1>4. Правовые основания обработки персональных данных</h1>
                <p>4.1. Правовыми основаниями обработки персональных данных Оператором являются:</p>
                <ul>
                    <li>Конституция Российской Федерации;</li>
                    <li>Трудовой кодекс Российской Федерации;</li>
                    <li>Гражданский кодекс Российской Федерации;</li>
                    <li>Указ Президента Российской Федерации от 27.04.2023 № 307 &laquo;Об особенностях правового положения отдельных категорий иностранных граждан и лиц без гражданства в Российской Федерации&raquo;;</li>
                    <li>Федеральный закон от 27.07.2006 № 149-ФЗ &laquo;Об информации, информационных технологиях и о защите информации&raquo;;</li>
                    <li>Федеральный закон от 27.07.2006 № 152-ФЗ &laquo;О персональных данных&raquo;;</li>
                    <li>Закон Российской Федерации от 19.04.1991 № 1032-I &laquo;О занятости населения в Российской Федерации&raquo;;</li>
                    <li>Государственная программа города Москвы &laquo;Социальная поддержка жителей Москвы&raquo;, утвержденная постановлением Правительства Москвы от 06.09.2011 № 420-ПП &laquo;Об утверждении Государственной программы города Москвы &laquo;Социальная поддержка жителей города Москвы&raquo;;</li>
                    <li>Устав Оператора;</li>
                    <li>договоры, заключаемые между Оператором и Пользователем;</li>
                    <li>согласие Пользователя на обработку персональных данных;</li>
                    <li>иные основания, когда согласие на обработку персональных данных не требуется в силу закона.</li>
                </ul>
                <h1>5. Объем и категории обрабатываемых персональных данных, категории Пользователей</h1>
                <p>5.1. Оператор вправе устанавливать требования к составу персональных данных Пользователей, которые должны обязательно предоставляться для использования Сайта, при этом Оператор руководствуется настоящей Политикой конфиденциальности, Конституцией Российской Федерации и иным применимым законодательством Российской Федерации и города Москвы.</p>
                <p>5.2. Содержание и объем обрабатываемых Оператором персональных данных:</p>
                <p>5.2.1. личная информация о Пользователе: фамилия, имя, отчество, пол, возраст; дата и место рождения; электронный адрес, номер телефона (домашний, мобильный), сведения о документе, удостоверяющем личность (серия, номер, дата выдачи, код подразделения, орган, выдавший документ), адрес регистрации по месту проживания, адрес фактического проживания; данные документов об образовании и (или) квалификации, профессиональной подготовке, сведения о повышении квалификации; семейное положение, сведения о составе семьи; сведения о трудовом стаже, предыдущих местах работы, СНИЛС; ИНН; анкета; фотографии; рекомендации, характеристики; сведения о деловых и иных личных качествах, носящих оценочный характер;</p>
                <p>5.2.2. иная информация, которая может быть запрошена дополнительно для осуществления функций, полномочий и обязанностей, возложенных законодательством Российской Федерации и города Москвы на Оператора либо исполнения заключаемых договоров.</p>
                <p>5.3. Посредством Сайта Оператором осуществляется сбор и обработка обезличенных данных о Посетителях: IP-адрес, вид операционной системы, тип браузера, географическое положение, поставщик услуг информационно-телекоммуникационной сети Интернет, сведения об использовании Сайта (в том числе сведения о посещенных страницах, перехода с других ресурсов), информация, автоматически получаемая при доступе к Сайту (в том числе с использованием cookies и сервисов интернет-статистики), сведения: о направленных запросах, отзывах и вопросах и иная информация, полученная в результате действий Пользователя.</p>
                <p>5.4. Оператор может обрабатывать персональные данные любых категорий Пользователей - посетителей Сайта, являющихся соискателями (кандидатами) в приеме на работу и (или) на обучение, представителями работодателей, а также иными субъектами персональных данных.</p>
                <p>5.5. Оператор обеспечивает соответствие содержания и объема обрабатываемых персональных данных заявленным целям обработки и, в случае необходимости, принимает меры по устранению их избыточности по отношению к заявленным целям обработки.</p>
                <p>5.6. Обработка специальных категорий персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни, Оператором не осуществляется.</p>
                <p>5.7. Трансграничная передача персональных данных Оператором не осуществляется.</p>
                <h1>6. Порядок и условия обработки персональных данных Пользователей и их передачи третьим лицам</h1>
                <p>6.1. Обработка персональных данных Пользователя осуществляется в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ &laquo;О персональных данных&raquo; следующими способами: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</p>
                <p>6.2. Пользователь принимает решение о предоставлении его персональных данных и дает Согласие свободно, своей волей и в своем интересе. Согласие на обработку персональных данных Пользователя вступает в силу со дня его принятия и действует в течение неопределенного срока и может быть отозвано на основании уведомления от Пользователя об отзыве согласия на обработку персональных данных.</p>
                <p>6.3. Хранение персональных данных осуществляется в форме, позволяющей определить Пользователя, в течение срока не дольше, чем этого требуют цели обработки персональных данных, кроме случаев, когда срок хранения персональных данных установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является Пользователь.</p>
                <p>6.4. В отношении персональных данных Пользователя сохраняется их конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц. При использовании отдельных сервисов Пользователь соглашается с тем, что определенная часть его персональных данных становится общедоступной.</p>
                <p>6.5. При утрате или разглашении персональных данных Оператор информирует Пользователя об утрате или разглашении персональных данных.</p>
                <p>6.6. Оператор принимает необходимые организационные и технические меры для защиты персональных данных Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</p>
                <p>6.7. Оператор совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.</p>
                <p>6.8. При осуществлении хранения персональных данных Оператор использует базы данных и сервера, находящиеся на территории Российской Федерации.</p>
                <p>6.9. Оператор собирает и хранит только те персональные данные, которые необходимы для целей обработки персональных данных Пользователей, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональных данных в течение определенного законом срока.</p>
                <p>6.10. Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия согласия или отзыв согласия Пользователем, а также выявление неправомерной обработки персональных данных.</p>
                <p>6.11. В случае получения уведомления от Пользователя об отзыве согласия на обработку персональных данных Оператор прекращает обработку персональных данных Пользователя в срок, не превышающий 10 рабочих дней с момента получения такого уведомления.</p>
                <p>Уведомление об отзыве согласия на обработку персональных данных направляется Пользователем на адрес электронной почты:&nbsp;<strong>czn@social.mos.ru</strong>, а также путем письменного обращения по юридическому адресу:&nbsp;<strong>129110, г. Москва, ул. Щепкина, д. 38, стр. 1.</strong></p>
                <p>6.12. Оператор вправе передать персональные данные Пользователя, исключительно в объеме, необходимом для достижения указанных в настоящей Политике конфиденциальности целей, следующим третьим лицам:</p>
                <ul>
                    <li>Департаменту труда и социальной защиты населения города Москвы (г. Москва, ул. Новая Басманная, 10, стр. 1);</li>
                    <li>Государственному бюджетному образовательному учреждению города Москвы дополнительного профессионального образования Центру профессиональных квалификаций и содействия трудоустройству &laquo;Профессионал&raquo; (г. Москва, ул. Академика Скрябина, 9, корп. 2, стр. 4);</li>
                    <li>государственным профессиональным образовательным организациям, подведомственным Департаменту образования и науки города Москвы;</li>
                    <li>работодателям.</li>
                </ul>
                <p>6.13. Передача третьим лицам персональных данных Пользователей осуществляется Оператором также в случаях, если она необходима для использования Пользователем определенного сервиса либо для исполнения определенного соглашения или договора с Пользователем, предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры.</p>
                <h1>7. Изменение и уничтожение персональных данных, запросы Пользователей</h1>
                <p>7.1. В случае подтверждения факта неточности персональных данных или неправомерности их обработки персональные данные подлежат их актуализации Оператором, или их обработка должна быть прекращена соответственно.</p>
                <p>7.2. Факт неточности персональных данных или неправомерности их обработки может быть установлен либо Пользователем, либо компетентными государственными органами Российской Федерации.</p>
                <p>7.3. По письменному запросу Пользователя или его представителя Оператор обязан сообщить информацию об осуществляемой им обработке персональных данных указанного субъекта. Такой запрос должен содержать номер основного документа, удостоверяющего личность Пользователя и его представителя и документ, подтверждающий права представителя на получение таких данных, сведения о дате выдачи указанного документа и выдавшем его органе, сведения, подтверждающие участие Пользователя в отношениях с Оператором (дата заключения договора, условное словесное обозначение и (или) иные сведения), либо сведения, иным образом подтверждающие факт обработки персональных данных Оператором, подпись Пользователя или его представителя.</p>
                <p>7.4. Если в запросе Пользователя не отражены все необходимые сведения или Пользователь не обладает правами доступа к запрашиваемой информации, то ему направляется мотивированный отказ.</p>
                <p>7.5. В порядке, предусмотренном пунктом 7.3 настоящей Политики конфиденциальности, Пользователь вправе требовать от Оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.</p>
                <p>7.6. При достижении целей обработки персональных данных, а также в случае отзыва Пользователем согласия, персональные данные подлежат уничтожению, если:</p>
                <ul>
                    <li>Оператор не вправе осуществлять обработку без согласия Пользователя;</li>
                    <li>иное не предусмотрено договором, стороной которого, выгодоприобретателем или поручителем по которому является Пользователь;</li>
                    <li>иное не предусмотрено иным соглашением между Оператором и Пользователем.</li>
                </ul>
                <h1>8. Ответственность за нарушение норм обработки и защиты персональных данных Пользователей Сайта</h1>
                <p>8.1. Лица, виновные в нарушении норм, регулирующих получение, обработку и защиту персональных данных Пользователей Сайта, привлекаются к дисциплинарной, материальной, гражданско-правовой, административной и уголовной ответственности в порядке, установленном действующим законодательством Российской Федерации.</p>
                <p>8.2. Моральный вред, причиненный Пользователю Сайта вследствие нарушения его прав, нарушения правил обработки персональных данных, установленных Федеральным законом от 27.07.2006 № 152-ФЗ &laquo;О персональных данных&raquo;, а также требований к защите персональных данных, установленных в соответствии с названным Федеральным законом, подлежит возмещению в соответствии с законодательством Российской Федерации. Возмещение морального вреда осуществляется независимо от возмещения имущественного вреда и понесенных Пользователем Сайта убытков.</p>
                <p>8.3. В случае утраты или разглашения конфиденциальной информации (в том числе, но не ограничиваясь контактных данных, пароля или логина Пользователя Сайта) Оператор не несет ответственности, если данная конфиденциальная информация:</p>
                <p>8.3.1. Стала публичным достоянием до ее утраты или разглашения.</p>
                <p>8.3.2. Была получена от третьей стороны до момента ее получения Оператором.</p>
                <p>8.3.3. Была разглашена с согласия Пользователя.</p>
                <h1>9. Разрешение споров</h1>
                <p>9.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем и Оператором, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).</p>
                <p>9.2. Получатель претензии в течение 30 календарных дней со дня получения претензии письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</p>
                <p>9.3. При недостижении соглашения спор будет передан на рассмотрение в суд в соответствии с действующим законодательством Российской Федерации.</p>
                <p>9.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Оператором применяется действующее законодательство Российской Федерации.</p>
                <h1>10. Дополнительные условия</h1>
                <p>10.1. Настоящая Политика конфиденциальности может быть изменена или прекращена Оператором в одностороннем порядке без предварительного уведомления Пользователя Сайта.</p>
                <p>10.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.</p>
                <p>10.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать по электронной почте:&nbsp;<strong>czn@social.mos.ru</strong>, а также по адресу:&nbsp;<strong>129110, г. Москва, ул. Щепкина, д. 38, стр. 1.</strong></p>
                <p>10.4. Действующая Политика конфиденциальности размещена на страницах по адресам: https://czn.mos.ru, https://cznmos.ru, https://mosfuture.ru</p>
                <p>&nbsp;</p>
            </div>

        </div>
    )
}
export default Policy;
