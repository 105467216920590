
const Success = ({typeModal, setTypeModal, address, setPlace}) => {
    const closeModal = () => {
        setTypeModal(null);
        setPlace(null);
    }

    const title = typeModal === 'already_registered' ? 'Вы уже зарегистрированы' : <span className="lg:leading-7">Благодарим вас за&nbsp;регистрацию на&nbsp;ярмарку трудоустройства!</span>;
    return (
        <div className="mt-5 min-h-fit w-full mx-0 lg:mx-28 flex mb-20 flex-col gap-3 md:gap-7 bg-orange px-6 lg:py-12 md:px-14 lg:px-20 xl:px-32 py-8 rounded-xl min-w-[340px] xl:max-w-[1230px]">
            <div className={`${typeModal === 'success_offline' ? 'gap-5 lg:gap-12' : 'gap-2 lg:gap-5'} flex flex-col text-white text-center items-center`}>
                <span className="font-bold text-base md:text-2xl lg:text-4xl uppercase">{title}</span>
                <div className="text-xs md:text-xl lg:text-3xl">
                    {
                        typeModal === 'success_offline' ? <span>Ждем вас по адресу {address}<br/>28 июня 2024 года</span> :
                        typeModal === 'success_online' ? <span>Ссылка на подключение к вебинарной комнате будет направлена <span className="whitespace-nowrap">e-mail</span></span> :
                        typeModal === 'already_registered' ? <span>Информация была направлена на указанную почту</span> : null
                    }
                </div>
            </div>
            {
               typeModal !== 'already_registered' ?
                   <div className="flex gap-3 items-center lg:mt-6 text-white w-full justify-center mt-3">
                       <svg className="min-h-[20px] md:h-[28px] lg:h-[35px] max-w-[20px] md:max-w-[28px] lg:max-w-[35px] shrink-0" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M15.6932 25.3333H18.4709V15.3333H15.6932V25.3333ZM16.9982 12.1666C17.4152 12.1666 17.7649 12.0282 18.0473 11.7513C18.3297 11.4745 18.4709 11.1314 18.4709 10.7222C18.4709 10.2972 18.3299 9.94092 18.0478 9.65342C17.7657 9.36592 17.4162 9.22217 16.9992 9.22217C16.5822 9.22217 16.2325 9.36592 15.9501 9.65342C15.6677 9.94092 15.5265 10.2972 15.5265 10.7222C15.5265 11.1314 15.6675 11.4745 15.9496 11.7513C16.2317 12.0282 16.5812 12.1666 16.9982 12.1666ZM17.0061 33.6666C14.7055 33.6666 12.5434 33.2291 10.52 32.3541C8.49655 31.4791 6.73018 30.287 5.22091 28.7777C3.71166 27.2684 2.51953 25.5014 1.64453 23.4766C0.769531 21.4518 0.332031 19.2882 0.332031 16.986C0.332031 14.6838 0.769531 12.5203 1.64453 10.4955C2.51953 8.47067 3.71166 6.70825 5.22091 5.20825C6.73018 3.70825 8.49723 2.52075 10.522 1.64575C12.5469 0.770752 14.7104 0.333252 17.0126 0.333252C19.3148 0.333252 21.4783 0.770752 23.5031 1.64575C25.528 2.52075 27.2904 3.70825 28.7904 5.20825C30.2904 6.70825 31.4779 8.47214 32.3529 10.4999C33.2279 12.5277 33.6654 14.6919 33.6654 16.9925C33.6654 19.2932 33.2279 21.4552 32.3529 23.4786C31.4779 25.5021 30.2904 27.2667 28.7904 28.7726C27.2904 30.2784 25.5265 31.4705 23.4987 32.349C21.4709 33.2274 19.3067 33.6666 17.0061 33.6666ZM17.0126 30.8888C20.8737 30.8888 24.1515 29.537 26.846 26.8333C29.5404 24.1296 30.8876 20.8472 30.8876 16.986C30.8876 13.1249 29.5421 9.84714 26.8512 7.15267C24.1602 4.45823 20.876 3.111 16.9987 3.111C13.1468 3.111 9.86906 4.45649 7.16537 7.14746C4.46164 9.83846 3.10978 13.1226 3.10978 16.9999C3.10978 20.8518 4.46164 24.1296 7.16537 26.8333C9.86906 29.537 13.1515 30.8888 17.0126 30.8888Z" fill="white"/>
                       </svg>
                       <span className="text-[10px] md:text-base lg:text-2xl">Если вы не видите письмо с подтверждением регистрации в&nbsp;своем почтовом ящике, пожалуйста, проверьте папку «спам»</span>
                   </div>
               : null
            }
            <span onClick={closeModal} className=" cursor-pointer mt-3 md:mt-6 flex w-full bg-white text-dark rounded-lg justify-center items-center text-base md:text-xl lg:text-[27px] font-bold py-3 md:py-7">На главную</span>
        </div>
    );
}

export default Success;
