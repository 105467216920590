import { Calendar, Clock } from "../icons";

const Info = () => {
    return (
        <>
            <div className="text-white">
                <div className="text-xl sm:text-3xl md:text-4xl lg:text-[58px] font-bold leading-[30px] sm:leading-[45px] md:leading-[55px] lg:leading-[70px] uppercase">
                    Всероссийская<br/>ярмарка<br/>трудоустройства
                </div>
                <div className="text-[11px] sm:text-xl md:text-2xl lg:text-[28px] max-w-[252px] sm:max-w-[450px] md:max-w-[663px] leading-[1rem] sm:leading-7 md:leading-[35px] mt-6 sm:mt-10 md:mt-12 font-medium">Приглашаем&nbsp;вас</div>
            </div>
            <div className="flex gap-2 md:gap-4 items-center mt-2 md:mt-6 text-dark text-base sm:text-2xl md:text-3xl font-semibold mb-7 md:mb-20">
                <div className="flex gap-2 sm:gap-4 px-2.5 py-1 sm:py-1.5 md:py-3 md:px-4 justify-center items-center bg-white/75 rounded-lg md:rounded-xl">
                    <Calendar/>
                    <p className="mt-0.5">28 июня</p>
                </div>
                <div className="flex gap-2 sm:gap-4 px-2.5 py-1 sm:py-1.5 md:py-3 md:px-4 justify-center items-center bg-white/75 rounded-lg md:rounded-xl">
                    <Clock/>
                    <p className="mt-0.5">10:00‒16:00</p>
                </div>
            </div>
        </>
    )
}

export default Info;
