import './App.css';
import {useEffect, useState} from "react";
import MainPage from "./components/Pages/MainPage";
import Policy from "./components/Pages/Policy";


function App() {
    const [itemPage, setItemPage] = useState('main');
    const pages = {main: <MainPage/>, policy: <Policy/>}

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);

        switch (true){
            case queryParams.get('page') === 'policy':
                setItemPage('policy');
                break;
            default:
                setItemPage('main');
        }
    }, []);


    return (
            <div className="App montserrat">
                {pages[itemPage]}
            </div>
    )
}

export default App;
