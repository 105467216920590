import Arrow from "../../../assets/icons/Arrow";
import "./HowWasIt.css";

const HowWasIt = () => {


  return (
    <div className="md:mt-20 mt-[24px] relative z-40 pr-5 sm:pr-8 md:pr-14">
      <div className="flex items-center gap-2 md:gap-5">
        <div className="text-white text-sm sm:text-xl md:text-2xl lg:text-[40px] font-bold leading-4 md:leading-[40px] flex-shrink-0 z-20">
          Как это было
        </div>
        <Arrow color="white" className="flex mt-0.5 md:mt-1 min-h-full z-10" />
      </div>
      <div className="flex flex-col items-center min-h-full h-min">
        <div className="video-container lg:max-w-4xl mt-[17px] md:mt-[45px] relative rounded-[25px] lg:rounded-[42px] opacity-65 cursor-pointer">
          <video
            className="flex w-fit lg:max-w-[816px] lg:max-h-[448px] rounded-[25px] lg:rounded-[42px] object-cover"
            poster="/img/video_preview.jpg"
            controls
            preload="metadata"
          >
            <source src="/video/How_was_it.mp4" type="video/mp4" />
            Ваш браузер не поддерживает видео-тег.
          </video>

        </div>
      </div>
    </div>
  );
};

export default HowWasIt;
