import Line from "../../Common/Line/Line";
import ScheduleListItem from "./ScheduleListItem";


const ScheduleList = ({title, schedule}) => {
    return (
        <div className="flex flex-col gap-4">
            <div className="flex gap-2 font-bold items-center">
                <div className="flex shrink-0 px-2 py-0.5 lg:py-1 lg:px-3 justify-center items-center bg-red rounded-xl md:rounded-2xl">{title}</div>
                <Line color="red"/>
            </div>
            <ul className="schedule_ul">
                {schedule && schedule.map(item => (
                    <ScheduleListItem key={item.id} {...item}/>
                ))}
            </ul>
        </div>
    )
}
export default ScheduleList;
