const Line = ({color}) => {
    const colorVar = {
        red: 'bg-orange',
        white: 'bg-white',
        dark: 'bg-dark',
    }
  return (
      <div className={`flex w-full items-center h-full`}>
          <span className={`${colorVar[color]} w-full h-px xl:h-0.5 `}></span>
          <span className={`${colorVar[color]} w-[0.25rem] h-[0.25rem] sm:w-[0.275rem] sm:h-[0.275rem] rounded-full -ml-[0.1rem] `}></span>
      </div>
  )
}
export default Line;
