import {useState} from "react";
import Form from "../Form";
import Success from "../Success";

const Modal = ({placeId, setPlace, typeModal, setTypeModal}) => {
    const [address, setAddress] = useState(null);
    return (
        <div
            className="bg-dark/20 min-h-screen h-full backdrop-blur-md justify-center flex overflow-x-hidden overflow-y-visible fixed inset-0 z-50 outline-none focus:outline-none"
        >
            <div className={`my-10 h-full relative min-w-[340px] ${typeModal !== 'form' ? 'flex items-center justify-center mx-10 md:mx-20' : 'w-full md:w-auto'} lg:mx-auto`}>
                {
                    typeModal === 'form' ?
                        <Form setPlace={setPlace} setAddress={setAddress} setTypeModal={setTypeModal} placeId={placeId}/> :
                        typeModal ? <Success typeModal={typeModal} setTypeModal={setTypeModal} setPlace={setPlace} address={address}/> :
                        setTypeModal(null)
                }
            </div>
        </div>
    );
}

export default Modal;
