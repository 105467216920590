import "../SecondBlock.scss";
import ScheduleList from "./ScheduleList";
import {
    scheduleListFirst,
    scheduleListFourth,
    scheduleListSecond,
    scheduleListThird,
    webinars
} from "../../../utils/variables";
import Link from "../../Common/Link/Link";
import {CareerCentre, CentreFuture, EducationCentre, Shabolovka} from "../icons";

const Details = ({address, station, place, setPlace, setTypeModal, placeId}) => {

    let data = [];
    let icon = null;
    const iconClassNames = 'h-[20px] md:h-[40px] lg:h-[55px]';

    switch(placeId) {
        case 1:
            data = scheduleListFirst;
            icon = <CentreFuture classNames={iconClassNames}/>;
            break;
        case 2:
            data = scheduleListThird;
            icon = <EducationCentre classNames={iconClassNames}/>;
            break;
        case 3:
            data = scheduleListSecond;
            icon = <CareerCentre classNames={iconClassNames}/>;
            break;
        case 4:
            data = scheduleListFourth;
            icon = <Shabolovka classNames={iconClassNames}/>;
            break;
        case 5:
            data = webinars;
            break;
    }

    return (
        <div className="flex w-full my-8 lg:my-14" key={placeId}>
            <div className="hidden lg:flex w-[12%] xl:w-[10%] mt-1">{icon ? icon : null}</div>
            <div className="flex flex-col gap-6 lg:gap-12 w-full md:w-[95%] lg:w-[76%]">
                {address && station ?
                    <div className="gap-3 md:gap-7 w-fit hidden lg:flex">
                        <div className="flex flex-col gap-0 md:gap-1.5 w-full">
                            <span
                                className="text-[0.7rem] sm:text-base md:text-lg lg:text-2xl xl:text-3xl 2xl:text-[32px] font-extrabold !shrink-0">{place}</span>
                            <div
                                className="flex gap-2 text-[8px] sm:text-xs md:text-sm lg:text-base xl:text-2xl font-medium">
                                <span>{address} | {station}</span>
                            </div>
                        </div>
                    </div>
                : null}
                <div className="w-full z-20 text-[11px] lg:text-[15px] flex flex-col gap-8" key={placeId}>
                    <div className="w-full z-20 text-[13px] lg:text-lg flex flex-col gap-4 lg:gap-8">
                        {data && data.map(schedule => {
                            return <ScheduleList {...schedule} placeId={placeId}/>
                        })}
                    </div>
                </div>
                <div className="flex justify-center w-full">
                    <Link text="Регистрация" color="red" place={placeId} setTypeModal={setTypeModal} type="form"
                          setPlace={setPlace}/>
                </div>

            </div>
        </div>

    )
}

export default Details;
