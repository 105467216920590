import Plus from "../icons/Plus";


const PlaceListItem = ({id, icon, place, address, station, setPlace, placeId}) => {

    const handleToggle = () => {
        setPlace(id)
        if(placeId && placeId === id){
            setPlace(null)
        }
    }

    return (
        <div className="relative col-span-1" key={id}>
            <div className={`${placeId === id ? 'bg-white' : 'bg-white/65'} flex z-50 gap-2 md:gap-5 relative text-dark rounded-xl p-4 md:p-6 w-full xl:max-w-[635px] h-full min-h-[130px] md:min-h-[250px] xl:min-h-[230px]`}>
                <Plus/>
                <div className='flex w-full'>
                    <div className="flex flex-col w-full">
                        <div className="flex flex-col gap-0 md:gap-2 w-full">
                            <div
                                className="text-sm sm:text-base md:text-2xl xl:text-[28px] font-semibold !shrink-0">{place}</div>
                            <div className="text-xs sm:text-sm md:text-xl xl:text-[26px] mt-1">{address}</div>
                        </div>
                        <div className="flex items-center gap-2 text-xs sm:text-sm md:text-xl xl:text-[26px] mt-2">
                            {icon}
                            {station}
                        </div>
                        <div className="flex w-full justify-end items-end h-full cursor-pointer mt-2 sm:mt-0 hover:scale-[1.01] transition duration-150 ease-in-out">
                            <span onClick={handleToggle} className="text-red text-xs md:text-lg lg:text-xl font-bold border-[0.1rem] lg:border-2 border-2xl rounded-3xl border-orange px-3 py-0.5">Подробнее</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PlaceListItem;
